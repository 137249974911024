import axios from 'axios'
import env from  '../envioments'
import removeNullable from "../tools/removeNullable";
export  class Config {

    getAll( filter = {}) {
        filter = removeNullable(Object.assign({},filter));
        return axios.get(`${env.remoteUrl}/admin/configs`, {params: filter})
            .then(res => res.data);
    }


    store(data = {}) {
        return axios.put(`${env.remoteUrl}/admin/configs`, data)
    }

}

export default new Config();
