<template>
    <div id="content">
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(onSubmit)" ref="form" name="form">

                <b-card   footer-tag="footer" class="p-0">
                    <template v-slot:header>
                        <h6 class="mb-0">Configuraciones</h6>
                    </template>
                    <b-card-text>

                        <b-container class="py-4" fluid="true">

                            <b-row class="mb-3">
                                <b-col lg="6">
                                    <div class="form-group ">
                                        <ValidationProvider name="Registrar tiempo de boletas de pago" :rules="{ required: true, numeric:true, between:{ min:1, max:10 }}"
                                                            v-slot="validationContext" >
                                            <div class="p-float-label">
                                                <InputText id="title" type="number" v-model="model.payment_ticket_back_years"  class="w-100"/>
                                                <b-form-invalid-feedback    :state="true" :class="{'d-block':true}">
                                                    {{ validationContext.errors.join(',')}}</b-form-invalid-feedback>
                                                <label for="title">Cantidad de años visible de la factura</label>

                                            </div>

                                        </ValidationProvider>
                                    </div>
                                </b-col>
                            </b-row>

                        </b-container>
                    </b-card-text>
                    <template v-slot:footer>
                        <Button :label="!loading ? 'Guardar':'Guardando'" :icon="loading ? 'pi pi-spin pi-spinner' :'pi pi-check'"  class="p-button-success" @click="onSubmit()" :disabled="loading"   />
                    </template>
                </b-card>




            </b-form>
        </validation-observer>


    </div>


</template>

<script>


    import removeNullable from "../../tools/removeNullable";
    import Config from "../../service/Config";
    import { BFormInvalidFeedback } from 'bootstrap-vue'

    export default {
        components: {
            BFormInvalidFeedback
        },
        data() {
            return {

                model:{
                    payment_ticket_back_years: 0
                },
                loading:false,
                showForm:false,
                crud: null,
                selected:null
            }

        },
        created() {
            this.crud = Config
            this.crud.getAll().then((res) => {
                Object.assign(this.model,res.data);
            })

        },
        methods: {

            initForm(){
                this.model = {
                    is_active:false,
                    users:[]
                };
            },
            onSubmit(){
                if(!this.$refs.observer.flags.invalid){
                    this.sendModelForm(this.model.id);

                }
            },

            onSuccess(){
                this.$toast.add({severity:'success', summary:"Guardado", life: 3000});
                this.loading = false;
            },
            onError(response){
                this.$toast.add({severity:'error', summary: response.data.message, life: 3000});
                this.loading = false;
            },
            sendModelForm(id = null){
                this.loading = true;
                let send = Object.assign({}, this.model);
                send = removeNullable(send);
                if(!id) {
                    this.crud.store(send).then( () => {
                        this.onSuccess();
                    },  (response) => {
                        this.onError(response)
                    });
                }else {
                    this.crud.update(id,send).then( () => {
                        this.onSuccess();
                    },  (response) => {
                        this.onError(response)
                    });
                }

            }

        }
    }
</script>

<style lang="scss">
    .p-dropdown-panel{
        margin-left: 15px;
    }
    .action{
        display: flex;
        > * {
            margin: auto;
        }

    }
</style>
<style lang="scss" scoped>

</style>
